<template>
  <transition name="fade">
    <div v-show="show" class="fixed inset-0 z-50 flex max-h-screen p-4 w-full items-center justify-center">
      <div class="absolute h-screen w-full bg-black opacity-25" @click.self="close" />
      <div class="relative w-full h-full p-4 bg-white rounded-xl shadow-lg overflow-x-hidden" :style="{ width: width }">
        <button class="absolute right-0 top-0 pr-3 pt-3 pl-1 pb-1" @click.stop="close">
          <icon name="close" class="block h-3 w-3 fill-gray-400" />
        </button>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    width: {
      required: true,
      type: String,
    },
    modalClasses: {
      type: String,
      default: '',
    },
  },
  beforeMount() {
    this.updateBodyScroll()
  },
  beforeUnmount() {
    this.updateBodyScroll()
  },
  watch: {
    show(value) {
      this.updateBodyScroll()
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    updateBodyScroll() {
      if (this.show) {
        return document.querySelector('body').classList.add('overflow-hidden')
      }
      return document.querySelector('body').classList.remove('overflow-hidden')
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
