<template>
  <div class="font-heading font-medium">
    <div>
      <menu-item :name="$t('Training')" class="text-ts-gray-bold mb-2 font-semibold" />
      <div class="mb-1">
        <menu-item
          :name="$t('Courses')"
          :route-link="route('front.courses')"
          :url="['account/courses*', 'account/enrolments*', 'account/contents*', 'account/']"
        >
          <icon name="book" class="mr-3 h-5 w-5" fill="currentColor" />
        </menu-item>
      </div>
      <div class="mb-1">
        <menu-item :name="$t('Certificates')" :route-link="route('front.certificates')" url="account/certificates*">
          <icon name="certificate" class="mr-3 h-5 w-5" fill="currentColor" />
        </menu-item>
      </div>
      <template v-if="$page.props.auth.user.organization && hasEhsAccess">
        <menu-item :name="$t('EHS')" class="text-ts-gray-bold mb-2 font-semibold" />
        <div>
          <menu-item :name="$t('Actions')" :route-link="route('front.actions')" url="account/actions*">
            <icon name="queue-list" class="mr-3 h-5 w-5" fill="none" />
          </menu-item>
          <menu-item :name="$t('Forms')" :route-link="route('front.filledForms.index')" url="account/forms*">
            <icon name="document" class="mr-3 h-5 w-5" />
          </menu-item>
        </div>
        <div>
          <menu-item :name="$t('Manuals')" :route-link="route('front.manuals')" url="account/manuals*">
            <icon name="stack" class="mr-3 h-5 w-5" />
          </menu-item>
        </div>
        <div class="mb-3">
          <menu-item :name="$t('Files')" :route-link="route('front.folders')" url="account/folders*">
            <icon name="document" class="mr-3 h-5 w-5" />
          </menu-item>
        </div>
      </template>
      <menu-item :name="$t('Account')" class="text-ts-gray-bold mb-2 font-semibold" />
      <div class="mb-1">
        <menu-item :name="$t('Settings')" :route-link="route('front.settings')" url="account/settings*">
          <icon name="setting" class="mr-3 h-6 w-6" style="margin-left: -0.15rem" />
        </menu-item>
      </div>
      <div class="mb-1">
        <menu-item :name="$t('Purchases')" :route-link="route('front.purchases')" url="account/purchases*">
          <icon name="purchase" class="mr-3 h-5 w-5" fill="currentColor" />
        </menu-item>
      </div>
      <div class="mb-3">
        <front-back-office-admin-menu-item />
        <front-logout-menu-item />
      </div>
      <menu-item
        v-if="$page.props.app.tenant.phone && $page.props.app.tenant.email"
        :name="$t('Support')"
        class="text-ts-gray-bold mb-2 font-semibold"
      />
      <front-phone-menu-item />
      <front-email-menu-item />
    </div>
  </div>
</template>

<script>
import { isUrl } from '@/Utils/Helpers'
export default {
  methods: {
    isUrl,
  },
  computed: {
    hasEhsAccess() {
      return this.$page.props.auth.user.organization?.has_subscription && this.$page.props.auth.user.has_ehs_access
    },
  },
}
</script>
